import {
  TaskGqlError,
  TaskUUID,
  UpdateTaskGqlQuery,
  UpdateTaskGqlStatus,
  UpdateTaskCacheKeys,
  TaskImplementationDate
} from '../../tasksTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface AcceptResultsInDraftTaskOptions {
  query: UpdateTaskGqlQuery;
  cacheKeys?: UpdateTaskCacheKeys;
}

export interface AcceptResultsInDraftTaskResponse<
  AcceptResultsInDraftTaskRecordType
> {
  acceptResultsInDraftTask: {
    status: UpdateTaskGqlStatus;
    recordUuid: TaskUUID;
    record: AcceptResultsInDraftTaskRecordType;
    errors: AcceptResultsInDraftTaskError;
  };
}

export interface AcceptResultsInDraftTaskInput {
  uuid: TaskUUID;
  implementationDate?: TaskImplementationDate;
}

export interface AcceptResultsInDraftTaskError {
  fullMessages: TaskGqlError;
}

const action = 'acceptResultsInDraftTask';

const scope = 'task';

const pluralScope = 'tasks';

function useAcceptResultsInDraftTask<AcceptResultsInDraftTaskRecordType>({
  query,
  cacheKeys
}: AcceptResultsInDraftTaskOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    AcceptResultsInDraftTaskInput,
    AcceptResultsInDraftTaskResponse<AcceptResultsInDraftTaskRecordType>,
    AcceptResultsInDraftTaskError,
    AcceptResultsInDraftTaskRecordType
  >({ action, pluralScope, scope, query, cacheKeys });

  return {
    acceptResultsInDraftTaskData: updateQueryData,
    acceptResultsInDraftTaskError: updateQueryError,
    acceptResultsInDraftTaskLoading: updateQueryLoading,
    acceptResultsInDraftTaskErrorMessage: updateQueryErrorMessage,
    acceptResultsInDraftTask: updateQuery,
    acceptResultsInDraftTaskReset: updateQueryReset
  };
}

export default useAcceptResultsInDraftTask;
