import { gql } from 'graphql-request';

import { TaskNanoID, TaskUUID } from '../tasksTypes';

export interface AcceptResultsInDraftTaskQueryResponse {
  uuid: TaskUUID;
  nanoId: TaskNanoID;
}

export const ACCEPT_RESULTS_IN_DRAFT_TASK_QUERY = gql`
  mutation AcceptResultsInDraftTask($uuid: ID!, $implementationDate: DateTime) {
    acceptResultsInDraftTask(
      input: { uuid: $uuid, implementationDate: $implementationDate }
    ) {
      recordUuid
      recordNanoId
      status
      record {
        uuid
        nanoId
      }
      errors {
        fullMessages
      }
    }
  }
`;
